<template>
  <div id="app" class="d-flex flex-column min-vh-100">
    <MainNav />


    <div v-if="$store.state.isLoading" class="loading-overlay2">
      <img src="@/assets/img/LOADING.gif" style="scale: 0.7 ;" alt="Loading Animation" />
    </div>

    <section>
      <router-view />
    </section>

    
  </div>
</template>




<script>
import MainNav from '@/components/MainNav.vue';
import axios from 'axios';
import en from '@/en.js';
import fr from '@/fr.js';
import nl from '@/nl.js';

export default {
  mixins: [en, fr, nl],
  components: {
    MainNav,
  },
  data() {
    return {
      highlightWidget: false,
      showTooltip: false,
      shipment: {
        country: "",
        price: "",
      }
    }
  },
  beforeCreate() {
    this.$store.commit('initializeStore')

    const token = this.$store.state.token
    if (token) {
      axios.defaults.headers.common['Authorization'] = "Token " + token
    } else {
      axios.defaults.headers.common['Authorization'] = ""
    }

  },
  mounted() {
    this.shipment = this.$store.state.shipment

    setTimeout(() => {
      this.highlightWidget = true;
      this.showTooltip = true;
    }, 5000);

    setTimeout(() => {
      this.highlightWidget = false;
      this.showTooltip = false;
    }, 15000);

  },
  computed: {
    isWorkingHours() {
      const belgiumTimezone = 'Europe/Brussels';
      const options = { timeZone: belgiumTimezone };
      const now = new Date().toLocaleString('en-US', options);

      const currentHour = new Date(now).getHours();

      const dayOfWeek = new Date(now).getDay();
      return dayOfWeek >= 1 && dayOfWeek <= 5 && currentHour >= 8 && currentHour < 17;
    },
    lang() {
      return this.$store.state.lang
    },
  },
  methods: {
    translate(prop) {
      return this[this.lang][prop];
    },
    handleMouseEnter() {
      this.showTooltip = false;
    },
  }


}


</script>

<style lang="scss">
@import '../node_modules/bulma';


.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #E5097F;
  border-color: #E5097F transparent #E5097F transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9998;
  display: none;

  &.is-active {
    display: block;
  }
}

.is-loading-bar {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  display: none;

  &.is-loading {
    display: block;
  }
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

footer {
  margin-top: auto;
}


.video-call-widget {
  position: fixed;
  bottom: 90px;
  right: 20px;
  z-index: 1000;
  width: 150px;
}

.video-call-widget2 {
  position: fixed;
  bottom: 90px;
  right: 20px;
  z-index: 1000;
  width: 150px;
}

.loading-overlay2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.loading-overlay2 img {
  position: relative;
}

.btn-video {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #FEEEF6;
  border-color: #FEEEF6;
  border-radius: 50px;
  color: black;
  font-weight: 600;
}

.btn-video:hover {
  background-color: #FEEEF6;
  font-weight: 600;
  width: 200px;
}

.button-text-video {
  display: none;
}

.btn-video:hover .button-text-video {
  display: inline;
}

.btn-video {
  transition: all 0.5s ease-in-out;
}

.btn-video2 {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #aaa;
  border-color: #aaa;
  border-radius: 50px;
  color: black;
  font-weight: bold;
}

.btn-video2:hover {
  background-color: #aaa;
  font-weight: bold;
  cursor: not-allowed;
}

.button-text-video2 {
  display: none;
}

.btn-video:hover .button-text-video2 {
  display: inline;
}

.btn-video2 {
  transition: all 0.5s ease-in-out;
}

//---------------wdiget alert 
@keyframes pulsate {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }

  50% {
    transform: scale(1.05);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0.5;
  }
}

.pulsate-effect {
  animation: pulsate 1s infinite;
}

.video-help-tooltip {
  position: absolute;
  top: -50px;
  left: 10%;
  width: 200px;
  transform: translateX(-50%);
  background-color: #FEEEF6;
  color: #E5097F;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  z-index: 1010;
  pointer-events: none;
}</style>