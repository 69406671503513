<template>
    <section style="margin-top: 30px; margin-bottom: 100px;">

        <div class="container mt-4">

            <div class="row justify-content-center">
                <div class="col-md-6 col-sm-12 mb-4">
                    <div class="p-4 rounded text-center border">
                        <div>
                            <img src="../assets/img/signup.png" alt="Signup Image">
                        </div>
                        <form @submit.prevent="submitForm" style="margin-top: 20px;">
                            <div class="form-group mb-3">
                        </div>
                        <div class="form-group mb-3">
                            <input type="email" class="form-control" :placeholder="translate('email')" required v-model="email" :class="{ 'fill-out': email, 'not-fill-out': !email }">
                        </div>
                        <div class="form-group mb-3">
                            <input type="password" class="form-control"  :placeholder="translate('password')" required v-model="password" :class="{ 'fill-out': password, 'not-fill-out': !password }">
                        </div>

                        
                        <div class="text-center mt-3">
                            <button type="submit" class="btn btn-login2 me-2">{{ translate('login') }}</button>
                            <br>
                            <div class="notification is-danger" v-if="errors.length" style="margin-top: 20px;">
                                <p v-for="error in errors" :key="error">{{ error }}</p>
                            </div>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios'
import { toast } from 'bulma-toast'
import en from '../en.js';
import fr from '../fr.js';
import nl from '../nl.js';


export default {
    name: 'LogIn',
    mixins: [en, fr, nl],
    data() {
        return {
            email: '',
            password: '',
            errors: []
        }
    },
    mounted() {
        document.title = 'Log In | Eurekali'
    },
    computed: {
        lang() {
            return this.$store.state.lang
        },
    },
    methods: {
        translate(prop) {
            return this[this.lang][prop];
        },
        async submitForm() {
            axios.defaults.headers.common["Authorization"] = ""
            sessionStorage.removeItem("token")
            const formData = {
                email: this.email,
                password: this.password
            }
            this.$store.commit('setIsLoading', true)
            await axios
                .post("/api/v1/admin/login/", formData)
                .then(response => {
                    const token = response.data.auth_token
                    this.$store.commit('setToken', token)

                    axios.defaults.headers.common["Authorization"] = "Token " + token
                    sessionStorage.setItem("token", token)
                    
                    const toPath = this.$route.query.to || '/shipments-progress'
                    this.$router.push(toPath)
                })
                .catch(error => {
                    if (error.response) {
                        this.errors.push(this.translate('login_error'))
                    } else {
                        this.errors.push('Something went wrong. Please try again')

                        console.log(JSON.stringify(error))
                    }
                })
                this.$store.commit('setIsLoading', false)
        }
    }
}
</script>


<style>
.btn-login2 {
    background-color: #E5097F;
    color: white;
    margin-left: 15px;
    border-radius: 20px;
    font-weight: bold;
    padding-left: 25px;
    padding-right: 25px;
}

.btn-login2:hover {
    background-color: #9b0656;
    color: white;
}
</style>