<template>
  <div ref="scrollTarget"></div>
  <div class="container-fluid" style="height: 100%;">
    <div class="row">
      <main class="col-md-12" style=" background-color: #F7F7F8;">
        <div class="row justify-content-center" style="margin-top: 20px; margin-bottom: 100px;">
          <div class="col-12 text-center">
            <template v-if="shipments.length">
              <div class="table-responsive-lg">

                <table class="table table-hover table-striped table-bordered table-responsive">
                  <thead class="table-dark">
                    <tr>
                      <th
                        style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff; border-top-left-radius: 12px;">
                        ID</th>
                      <th
                        style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff">
                        {{ translate('tracking_number') }}</th>
                      <th
                        style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff">
                        Order date</th>
                      <th
                        style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff">
                        Service point</th>
                      <th
                        style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff">
                        {{ translate('sender') }}</th>
                      <th
                        style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff">
                        {{ translate('ship_from') }}</th>
                      <th
                        style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff">
                        {{ translate('ship_to') }}</th>
                      <th
                        style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff">
                        {{ translate('weight') }}</th>
                      <th
                        style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff">
                        Transporter</th>
                      <th
                        style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff">
                        Tracking number</th>
                      <th
                        style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff">
                        Pickup refrence</th>
                      <th
                        style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff">
                        Shipping date</th>
                      <th
                        style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff">
                        Delivery date</th>
                      <th
                        style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff; border-top-right-radius: 12px;">
                        {{ translate('details') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="shipment in shipments" :key="shipment.shipment_id">
                      <td style="vertical-align: middle; text-align: center;"> {{ shipment.shipment_id }}</td>
                      <td
                        style="vertical-align: middle; text-align: center; cursor: pointer; text-decoration: underline; font-weight: 600; color: #E5097F;"
                        @click="goToShipmentPage(shipment.tracking_number)"> {{ shipment.tracking_number }}</td>

                      <td style="vertical-align: middle; text-align: center; "> {{ shipment.order_date }}</td>
                      <td style="vertical-align: middle; text-align: center; "> {{ shipment.servicepoint }}</td>
                      <td style="vertical-align: middle; text-align: center;"> {{ shipment.sender }}</td>
                      <td style="vertical-align: middle; text-align: center;"> {{ shipment.ship_from }}</td>
                      <td style="vertical-align: middle; text-align: center;"> {{ shipment.ship_to }}</td>
                      <td style="vertical-align: middle; text-align: center;"> {{ shipment.package_type }} ({{
                        shipment.weight }} kg)
                      </td>
                      <td style="vertical-align: middle; text-align: center;"> {{ shipment.transporter }}</td>
                      <td style="vertical-align: middle; text-align: center;"> {{ shipment.transporter_number }}</td>
                      <td style="vertical-align: middle; text-align: center;"> {{ shipment.pickup_refrence }}</td>
                      <td style="vertical-align: middle; text-align: center;"> {{ shipment.shipping_date }}</td>
                      <td style="vertical-align: middle; text-align: center;"> {{ shipment.delivery_date }}</td>
                      <td
                        style="vertical-align: middle; text-align: center; color: #E5097F; cursor: pointer; text-decoration: underline;">
                        <span @click="selectShipment(shipment)">{{ translate('see_details') }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </template>
            <template v-else>
              <div class="container"
                style="text-align: center; color: white; font-weight: bold; font-size: 1.5em; background-color: #F1A8D0; padding: 60px; border-radius: 20px;">
                {{ translate('no_orders') }}
              </div>
            </template>

            <div v-if="selectedShipment" class="modal">
              <div class="modal-content">
                <span style="text-align: right;" @click="closeModal" class="close">&times;</span>
                <div style="text-align: center;">
                  <p style="color: #E5097F;"><strong style="color: #E5097F;">{{ translate('tracking_number') }} :
                    </strong> {{ selectedShipment.tracking_number }}</p>
                </div>
                <div class="modal-body">
                  <div class="column">
                    <p><strong>Pickup address : </strong> {{ selectedShipment.pickup_address }}</p>
                    <p><strong>Delivery address : </strong> {{ selectedShipment.receiver_address }}</p>

                    <br>
                    <p><strong>Sender</strong></p>
                    <p><span style="font-weight: 600;">Full Name : </span>{{ selectedShipment.sender_name }}</p>
                    <p><span style="font-weight: 600;">Email : </span>{{ selectedShipment.sender_email }}</p>
                    <p><span style="font-weight: 600;">Phone : </span>{{ selectedShipment.sender_phone }}</p>
                    <p><span style="font-weight: 600;">Address : </span>{{ selectedShipment.sender_address }}</p>
                    <br>

                    <p><strong>Receiver</strong></p>
                    <p><span style="font-weight: 600;">Full Name : </span>{{ selectedShipment.receiver_name }}</p>
                    <p><span style="font-weight: 600;">Email : </span>{{ selectedShipment.receiver_email }}</p>
                    <p><span style="font-weight: 600;">Phone : </span>{{ selectedShipment.receiver_phone }}</p>
                    <p><span style="font-weight: 600;">Address : </span>{{ selectedShipment.receiver_address }}</p>
                  </div>
                  <div class="column">
                    <p><strong>{{ translate('package_type') }} : </strong> {{ selectedShipment.package_type }}</p>
                    <p><strong>{{ translate('weight') }} : </strong> {{ selectedShipment.weight }}</p>
                    <br>
                    <p><strong>{{ translate('shipping_date') }} : </strong> {{ selectedShipment.shipping_date }}</p>
                    <p><strong>{{ translate('delivery_date') }} : </strong> {{ selectedShipment.delivery_date }}</p>
                    <p><strong>Transporter : </strong> {{ selectedShipment.transporter }}</p>
                    <p><strong>{{ translate('price') }} : </strong> <span class="badge bg-success rounded-pill"
                        style="font-size: 0.9em;">{{ selectedShipment.price }} &euro;</span></p>
                    <br>
                    <p><strong>Discount : </strong> {{ selectedShipment.discount }}%</p>
                    <p><strong>Assurance : </strong> {{ selectedShipment.insurance_option }}</p>
                    <p><strong>Printer request : </strong> {{ selectedShipment.printer }}</p>

                    <br>
                    <p><strong>Proforma invoice : </strong>
                      <i v-if="selectedShipment.proformainvoice" class="fa fa-print"
                        @click="printProformaInvoice(selectedShipment.shipment_id)"
                        style="cursor: pointer; color: #E5097F; font-size: x-large;"></i>
                      <i v-else class="fa fa-print" aria-hidden="true"
                        style="color: #aaa; font-size: x-large; cursor: not-allowed;"></i>
                    </p>
                    <p><strong>Sale ticket : </strong>
                      <i v-if="selectedShipment.saleticket === 'True'" class="fa fa-print"
                        @click="printsaleticket(selectedShipment.saleticket_id)"
                        style="cursor: pointer; color: #E5097F; font-size: x-large;"></i>
                      <i v-else class="fa fa-print" aria-hidden="true"
                        style="color: #aaa; font-size: x-large; cursor: not-allowed;"></i>
                    </p>
                    <p><strong>Shipping label : </strong>
                      <i v-if="selectedShipment.shippinglabel === 'True'" class="fa fa-print"
                        @click="printshippinglabel(selectedShipment.shipment_id)"
                        style="cursor: pointer; color: #E5097F; font-size: x-large;"></i>
                      <i v-else class="fa fa-print" aria-hidden="true"
                        style="color: #aaa; font-size: x-large; cursor: not-allowed;"></i>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Chart from 'chart.js/auto';
import en from '../en.js';
import fr from '../fr.js';
import nl from '../nl.js';


export default {
  name: 'Dashboard',
  mixins: [en, fr, nl],
  data() {
    return {
      user: {},
      today_shipments: "",
      progress_shipments: "",
      delivered_shipments: "",
      all_shipments: "",
      today_date: "",
      shipments_statistics: [],
      commissions_statistics: [],
      commission_amount: "",
      shipments: [],
      company: {},
      selectedShipment: null
    }
  },
  computed: {
    lang() {
      return this.$store.state.lang
    },
  },
  async created() {

    this.$store.commit('setIsLoading', true)
    await axios.get('/api/v1/users/me/')
      .then(async response => {
        this.user = response.data
        await this.getServicePoint(this.user.id)
      })
      .catch(error => {
        console.log(error)
      })
    this.$store.commit('setIsLoading', false)


    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();

    this.today_date = `${day}/${month}/${year}`;

  },
  async mounted() {
    document.title = 'Dashboard | Orders';
    const element = this.$refs.scrollTarget;
    element.scrollIntoView({ behavior: 'smooth' });
    window.scrollTo(0, 0);

    this.$store.commit('setIsLoading', true)
    await this.fetchShipments()
    this.$store.commit('setIsLoading', false)

  },
  methods: {
    translate(prop) {
      return this[this.lang][prop];
    },
    async fetchShipments() {
      try {
        const response = await axios.post('/api/v1/admin/get-orders-delivered/');
        this.shipments = response.data['shipments'];
      } catch (error) {
        console.error('Error fetching shipments:', error);
      }
    },
    goToShipmentPage(shipmentId) {
      this.$router.push(`/track/${shipmentId}`);
    },
    editShipment(shipmentId) {
      this.$router.push(`/edit/shipment/${shipmentId}`);
    },
    async getServicePoint(user_id) {
      await axios.post('/api/v1/getservicepoint/')
        .then(response => {
          this.company = response.data
        })
        .catch(error => {
          console.log(JSON.stringify(error))
        })
    },
    async printshippinglabel(shipment_id) {
      const url = `https://api.eurekali-point.com/api/v1/shhippinglabel/print/${shipment_id}/`;
      window.open(url, '_blank');
    },
    async printsaleticket(saleticket_id) {
      const url = `https://api.eurekali-point.com/api/v1/saleticket/print/${saleticket_id}/`;
      window.open(url, '_blank');
    },
    async printProformaInvoice(shipment_id) {
      const url = `https://api.eurekali-point.com/api/v1/proformainvoice/print/${shipment_id}/`;
      window.open(url, '_blank');
    },
    selectShipment(shipment) {
      this.selectedShipment = shipment;
    },
    closeModal() {
      this.selectedShipment = null;
    },
    logout() {
      axios.defaults.headers.common["Authorization"] = ""
      sessionStorage.removeItem("token")
      this.$store.commit('removeToken')
      this.$store.commit('clearShipment')
      this.$router.push('/login')
    }
  }
}
</script>

<style scoped>
.custom {
  border: none;
  cursor: pointer;
  font-size: 15px;
  padding-bottom: 10px;

}

.custom:hover {
  color: #E5097F;
  font-weight: bold;
}


.active-item {
  color: #E5097F;
  font-weight: bold;
}

.img-item {
  margin-right: 8px;
}

.red-header tr {
  background-color: red;
  color: white;
}

.btn-new-ship {
  color: #E5097F;
  font-weight: bold;
  background-color: #fff;
  border: 2px solid #E5097F;
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 2px;
  padding-top: 2px;
}

.btn-new-ship:hover {
  background-color: #9b0656;
  color: white;
  border: 2px solid #9b0656;
}


.btn-more {
  color: #fff;
  font-weight: bold;
  background-color: #E5097F;
  border: 2px solid #E5097F;
  margin-left: 10px;
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
}

.btn-more:hover {
  background-color: #9b0656;
  border: 2px solid #9b0656;
}

.btn-request {
  background-color: #BCF2C0;
  margin-left: 12px;
  border-radius: 20px;
  border: 2px solid #37B980;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
  font-weight: bold;
}

.btn-request:hover {
  background-color: #37B980;
  color: white;
}


.box-shadow-custom {
  box-shadow: 1px 1px 1px #aaa, -1px -1px 1px #aaa;
}


.bg-progress {
  background-color: #D5CB41;
}

.bg-delivered {
  background-color: #41D54B;
}

.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  color: black;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-body {
  display: flex;
  justify-content: space-between;
}

.column {
  width: 50%;
  text-align: left;
}

.modal-content {
  background-color: #FEEEF6;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 70%;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>
