<template>
  <div class="container">
    {{ shipment }}
  </div>
</template>

<script>
export default {
  name: 'StudentTable',
  data() {
    return {
      shipment : this.$store.state.shipment
    };
  },
  methods: {
    selectStudent(student) {
      this.selectedStudent = student;
    },
    closeModal() {
      this.selectedStudent = null;
    },
  },
};
</script>

<style scoped>
/* Style for the modal */
.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}

.modal-body {
  display: flex;
  justify-content: space-between;
}

.column {
  width: 45%; /* Or whatever width you prefer */
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>
