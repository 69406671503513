<template>
    <div ref="scrollTarget"></div>
    <div class="container">
        <div class="row" style="margin-top: 20px; margin-bottom: 60px">
            <div class="col-6">
                <div style="color: #E5097F; font-weight: 600; margin-bottom: 15px;"> Shipments in progress : {{ shipments_progress }}</div>
              <div class="row border" style="width: 80%; border-radius: 15px;padding: 10px; background-color: #fff;">
                <div>
                  <apexchart type="donut" :options="options1" :series="series1"></apexchart>
                </div>
              </div>
            </div>
            <div class="col-6">
                <div style="color: #E5097F; font-weight: 600; margin-bottom: 15px;"> Shipments : {{ shipments_total }}</div>
              <div class="row border" style="width: 80%; border-radius: 15px; padding: 10px;  background: #fff ">
                <div>
                    <apexchart type="donut" :options="options2" :series="series2"></apexchart>
                </div>
              </div>
            </div>
          </div>

          <div class="row" style="margin-top: 20px; margin-bottom: 60px">
            <div class="col-6">
                <div style="color: #E5097F; font-weight: 600; margin-bottom: 15px;"> Service points : {{ service_points_active + service_points_not_active }}</div>
              <div class="row border" style="width: 80%; border-radius: 15px;padding: 10px; background-color: #fff;">
                <div>
                  <apexchart type="donut" :options="options3" :series="series3"></apexchart>                  
                </div>
              </div>
            </div>
          </div>
    </div>
  </template>
    
  <script>
  import axios from 'axios'
  import { toast } from 'bulma-toast'
  import Swal from 'sweetalert2'
  import VueApexCharts from 'vue3-apexcharts';
  import { loadStripe } from '@stripe/stripe-js';
  
  
  export default {
    name: 'Dashboard',
    components: {
      apexchart: VueApexCharts,
    },
    data() {
      return {
        shipments_total: "",
        shipments_progress: "",
        shipments_pickup: "",
        shipments_not_pickup: "",
        shipments_pending: "",
        shipments_delivered: "",
        service_points: "",
        service_points_active: "",
        service_points_not_active: "",
  
  
        //data for charts 

        options1: {},
        series1: [],

        options2: {},
        series2: [],

        options3: {},
        series3: []
  
        
  
      }
    },

    async mounted() {
      document.title = 'Dashboard | Eurekali';
      const element = this.$refs.scrollTarget;
      element.scrollIntoView({ behavior: 'smooth' });
      window.scrollTo(0, 0);
  
      await this.getStatistics();  
  
      

      this.series1 = [this.shipments_pickup, this.shipments_not_pickup]
      this.options1 = {
    labels: [`Picked up`, 'Not picked up'],
    colors:['#13c810','#F44336']
  }

  this.series2 = [this.shipments_progress, this.shipments_pending, this.shipments_delivered]
      this.options2 = {
    labels: ['In progress', 'Pending', 'Delivered'],
    colors:['#ccdf0f','#F44336','#13c810']
  }

  this.series3 = [this.service_points_active, this.service_points_not_active]
      this.options3 = {
    labels: ['Active', 'No Active'],
    colors:['#13c810','#F44336']
  }  
  
    },
    methods: {
      async getStatistics() {
        await axios.post('/api/v1/admin/get-statistics/')
          .then(response => {
            this.shipments_total = response.data['shipments_total']
            this.shipments_progress = response.data['shipments_progress']
            this.shipments_pickup = response.data['shipments_pickup']
            this.shipments_not_pickup = response.data['shipments_not_pickup']
            this.shipments_pending = response.data['shipments_pending']
            this.shipments_delivered = response.data['shipments_delivered']
            this.service_points = response.data['service_points']
            this.service_points_active = response.data['service_points_active']
            this.service_points_not_active = response.data['service_points_not_active']
          })
          .catch(error => {
  
            toast({
              message: 'Failed to get Statistics',
              type: 'is-danger',
              dismissible: true,
              pauseOnHover: true,
              duration: 2000,
              position: 'top-right',
            })
            console.log(JSON.stringify(error))
          })
      },
      
      
    
    
    }
  }
  </script>
    
  <style scoped>
  .custom {
    border: none;
    cursor: pointer;
    font-size: 15px;
    padding-bottom: 10px;
  
  }
  
  .custom:hover {
    color: #E5097F;
    font-weight: bold;
  }
  
  
  .active-item {
    color: #E5097F;
    font-weight: bold;
  }
  
  .img-item {
    margin-right: 3px;
  }
  
  .red-header tr {
    background-color: red;
    color: white;
  }
  
  .btn-new-ship {
    color: #E5097F;
    font-weight: bold;
    background-color: #fff;
    border: 2px solid #E5097F;
    border-radius: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 2px;
    padding-top: 2px;
  }
  
  .btn-new-ship:hover {
    background-color: #9b0656;
    color: white;
    border: 2px solid #9b0656;
  }
  
  
  .btn-more {
    color: #fff;
    font-weight: bold;
    background-color: #E5097F;
    border: 2px solid #E5097F;
    margin-left: 10px;
    border-radius: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
    padding-top: 5px;
  }
  
  .btn-pay {
    color: #fff;
    font-weight: 500;
    background-color: #E5097F;
    border: 2px solid #E5097F;
    border-radius: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 0px;
    padding-top: 0px;
  }
  
  .btn-refund {
    color: #fff;
    font-weight: bold;
    background-color: #64C96B;
    border: 2px solid #64C96B;
    margin-left: 10px;
    border-radius: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
    padding-top: 5px;
  }
  
  .btn-more-deactivate {
    color: #fff;
    font-weight: bold;
    background-color: #C4C4C4;
    border: 2px solid #C4C4C4;
    margin-left: 10px;
    border-radius: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
    padding-top: 5px;
    cursor: not-allowed;
  }
  
  .btn-pay-deactivate {
    color: #fff;
    font-weight: 500;
    background-color: #C4C4C4;
    border: 2px solid #C4C4C4;
    border-radius: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 0px;
    padding-top: 0px;
    cursor: not-allowed;
  }
  
  .btn-more:hover {
    background-color: #9b0656;
    border: 2px solid #9b0656;
  }
  
  .btn-pay:hover {
    background-color: #9b0656;
    border: 2px solid #9b0656;
  }
  
  .btn-refund:hover {
    background-color: #255c29;
    border: 2px solid #255c29;
  }
  
  .btn-request {
    background-color: #BCF2C0;
    margin-left: 12px;
    border-radius: 20px;
    border: 2px solid #37B980;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
    padding-top: 5px;
    font-weight: bold;
  }
  
  .btn-request:hover {
    background-color: #37B980;
    color: white;
  }
  
  
  .box-shadow-custom {
    box-shadow: 1px 1px 1px #aaa, -1px -1px 1px #aaa;
  }
  
  .text-green {
    color: #37B980;
  }
  
  .text-red {
    color: red;
  }
  
  .custom-background1 {
    position: relative;
    background-image: url('../assets/img/shipments_panding.svg');
    background-size: 25%;
    background-position: left bottom;
    background-repeat: no-repeat;
  }
  
  .custom-background2 {
    position: relative;
    background-image: url('../assets/img/shipments_progress.svg');
    background-size: 25%;
    background-position: left bottom;
    background-repeat: no-repeat;
  }
  
  .custom-background3 {
    position: relative;
    background-image: url('../assets/img/shipments_delivred.svg');
    background-size: 25%;
    background-position: left bottom;
    background-repeat: no-repeat;
  }
  
  .modal {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
  }
  
  .modal-body {
    display: flex;
    justify-content: space-between;
  }
  
  .column {
    width: 45%;
  }
  
  .modal-content {
    background-color: #FEEEF6;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 50%;
  }
  
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  </style>
    