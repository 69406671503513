<template>
    <div ref="scrollTarget"></div>
    <div class="container-fluid" style="height: 100%;">
        <div class="row">

            <!-- Content Area -->
            <main class="col-md-12" style=" background-color: #F7F7F8;">



                <div class="row justify-content-center" style="margin-top: 20px; margin-bottom: 100px;">
                    <!-- First Table -->
                    <div class="col-12 text-center">
                        <template v-if="users.length">
                            <div class="table-responsive-lg">
                                <table class="table table-hover table-striped table-bordered table-responsive">
                                    <thead class="table-dark">
                                        <tr>
                                            <!-- Add the 'white-space: nowrap;' and 'text-align: center;' styles to table headers (th) -->
                                            <th
                                                style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff; border-top-left-radius: 12px;">
                                                ID</th>
                                            <th
                                                style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff">
                                                Created at</th>
                                                <th
                                                style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff;">
                                                Company</th>
                                            <th
                                                style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff">
                                                Shipments</th>
                                            <th
                                                style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff">
                                                Refund number</th>
                                            <th
                                                style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff">
                                                Commissions</th>
                                            <th
                                                style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff;">
                                                Cash payments</th>
                                            <th
                                                style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff;">
                                                Additional cash</th>
                                                <th
                                                style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff;">
                                                Total</th>
                                                <th
                                                style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff;">
                                                Status</th>
                                                <th
                                                style="vertical-align: middle; text-align: center; color: white; background-color: #E5097F; border: 1px solid #fff; border-top-right-radius: 12px;">
                                                Edit</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="user in users" :key="user.id">
                                            <td style="vertical-align: middle; text-align: center; "> {{ user.id }}</td>
                                            <td style="vertical-align: middle; text-align: center; "> {{ user.created_at
                                            }}
                                            </td>
                                            <td style="vertical-align: middle; text-align: center; "> {{ user.servicepoint }}
                                            </td>
                                            <td style="vertical-align: middle; text-align: center; "> {{ user.shipments }}</td>
                                            <td style="vertical-align: middle; text-align: center;"> {{ user.refund_number }}</td>
                                            <td style="vertical-align: middle; text-align: center;"> {{ user.commissions_amount }}
                                            </td>
                                            <td style="vertical-align: middle; text-align: center;"> {{ user.cashpayments_amount }}</td>
                                            <td style="vertical-align: middle; text-align: center;"> {{ user.additionalcash_amount }}</td>
                                            <td style="vertical-align: middle; text-align: center;"> {{ user.total }}</td>
                                            <td :class="{ 'green-class': user.is_paid, 'red-class': !user.is_paid }" style="vertical-align: middle; text-align: center;"> {{ user.is_paid ? 'Paid' : 'No Paid' }}</td>
                                            <td
                                                style="vertical-align: middle; text-align: center; color: #E5097F; cursor: pointer; text-decoration: underline;">
                                                <span @click="selectShipment(user)">{{ translate('see_details') }}</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </template>
                        <template v-else>
                            <div class="container"
                                style="text-align: center; color: white; font-weight: bold; font-size: 1.5em; background-color: #F1A8D0; padding: 60px; border-radius: 20px;">
                                {{ translate('no_orders') }}
                            </div>
                        </template>

                        <div v-if="selectedShipment" class="modal">
                            <div class="modal-content">
                                <span style="text-align: right;" @click="closeModal" class="close">&times;</span>
                                <div style="text-align: center;">
                                    <p style="color: #E5097F;"><strong style="color: #E5097F;">Service-point id :</strong>
                                        {{ selectedShipment.id }}</p>
                                </div>
                                <div class="modal-body">
                                    <div class="column">
                                        <p>
                                        <div class="row">
                                            <div class="col">
                                                <label class="label-control"
                                                    style="color: black; font-weight: 600; margin-bottom: 5px;">Invoice number</label>
                                                <input type="text" class="form-control" placeholder="Invoice number" required
                                                    v-model="selectedShipment.refund_number">
                                            </div>
                                        </div>
                                        </p>
                                        <br>
                                        <p>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox"
                                                v-model="selectedShipment.is_paid" id="flexCheckChecked5">
                                            <label class="form-check-label" for="flexCheckChecked5">
                                                Paid
                                            </label>
                                        </div>
                                        </p>
                                    </div>
                                    <div class="column">
                                        <p>
                                        
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <button class="btn btn-success" style="width: 200px;"
                                        @click="modifyUser(selectedShipment.id)">Edit information</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    </div>
</template>
      
<script>
import axios from 'axios'
import Chart from 'chart.js/auto';
import Swal from 'sweetalert2'
import en from '../en.js';
import fr from '../fr.js';
import nl from '../nl.js';


export default {
    name: 'Dashboard',
    mixins: [en, fr, nl],
    data() {
        return {
            user: {},
            today_shipments: "",
            progress_shipments: "",
            delivered_shipments: "",
            all_shipments: "",
            today_date: "",
            shipments_statistics: [],
            commissions_statistics: [],
            commission_amount: "",
            users: [],
            company: {},
            selectedShipment: null
        }
    },
    computed: {
        lang() {
            return this.$store.state.lang
        },
    },
    async created() {

        this.$store.commit('setIsLoading', true)
        await axios.get('/api/v1/users/me/')
            .then(async response => {
                this.user = response.data
                await this.getServicePoint(this.user.id)
            })
            .catch(error => {
                console.log(error)
            })
        this.$store.commit('setIsLoading', false)


        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const year = today.getFullYear();

        this.today_date = `${day}/${month}/${year}`;

    },
    async mounted() {
        document.title = 'Dashboard | Orders';
        const element = this.$refs.scrollTarget;
        element.scrollIntoView({ behavior: 'smooth' });
        window.scrollTo(0, 0);

        this.$store.commit('setIsLoading', true)
        await this.fetchShipments()
        this.$store.commit('setIsLoading', false)

    },
    methods: {
        translate(prop) {
            return this[this.lang][prop];
        },
        async fetchShipments() {
            try {
                const response = await axios.post('/api/v1/admin/get-refunds-v2/');
                this.users = response.data['refunds'];
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        },
        goToShipmentPage(shipmentId) {
            this.$router.push(`/track/${shipmentId}`);
        },
        editShipment(shipmentId) {
            this.$router.push(`/edit/shipment/${shipmentId}`);
        },
        async getServicePoint(user_id) { },
        selectShipment(shipment) {
            this.selectedShipment = shipment;
        },
        closeModal() {
            this.selectedShipment = null;
            this.fetchShipments()
        },
        logout() {
            axios.defaults.headers.common["Authorization"] = ""
            sessionStorage.removeItem("token")
            this.$store.commit('removeToken')
            this.$store.commit('clearShipment')
            this.$router.push('/login')
        },
        modifyUser(user_id) {
            Swal.fire({
                icon: 'question',
                title: 'Edit Refund',
                text: 'Are you sure you want to edit ?',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                confirmButtonColor: '#4BB543',
                cancelButtonColor: '#ff0e0e',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.editUser(user_id)
                }
            });
        },
        async editUser(user_id) {
            const arra = this.users.filter((user) => user.id === user_id)
            const user_selected = arra[0]
            const formData = {
                refund_number: user_selected.refund_number,
                is_paid: user_selected.is_paid,
                
            }
            this.$store.commit('setIsLoading', true)
            await axios
                .post(`/api/v1/admin/edit-refund-v2-details/${user_id}/`, formData)
                .then(response => {

                    this.fetchShipments();
                    this.closeModal();
                })
                .catch(error => {
                    console.log(JSON.stringify(error))
                })
            this.$store.commit('setIsLoading', false)
        }
    }
}
</script>
      
<style scoped>
.custom {
    border: none;
    cursor: pointer;
    font-size: 15px;
    padding-bottom: 10px;

}

.custom:hover {
    color: #E5097F;
    font-weight: bold;
}


.active-item {
    color: #E5097F;
    font-weight: bold;
}

.img-item {
    margin-right: 8px;
}

.red-header tr {
    background-color: red;
    color: white;
}

.btn-new-ship {
    color: #E5097F;
    font-weight: bold;
    background-color: #fff;
    border: 2px solid #E5097F;
    border-radius: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 2px;
    padding-top: 2px;
}

.btn-new-ship:hover {
    background-color: #9b0656;
    color: white;
    border: 2px solid #9b0656;
}


.btn-more {
    color: #fff;
    font-weight: bold;
    background-color: #E5097F;
    border: 2px solid #E5097F;
    margin-left: 10px;
    border-radius: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
    padding-top: 5px;
}

.btn-more:hover {
    background-color: #9b0656;
    border: 2px solid #9b0656;
}

.btn-request {
    background-color: #BCF2C0;
    margin-left: 12px;
    border-radius: 20px;
    border: 2px solid #37B980;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
    padding-top: 5px;
    font-weight: bold;
}

.btn-request:hover {
    background-color: #37B980;
    color: white;
}


.box-shadow-custom {
    box-shadow: 1px 1px 1px #aaa, -1px -1px 1px #aaa;
}


.bg-progress {
    background-color: #D5CB41;
}

.bg-delivered {
    background-color: #41D54B;
}

.modal {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    color: black;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-body {
    display: flex;
    justify-content: space-between;
}

.column {
    width: 50%;
    text-align: left;
}

.modal-content {
    background-color: #FEEEF6;
    margin: 10% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 70%;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.green-class{
    background-color: #13c810;
    color: white;
}
.red-class{
    background-color: #F44336;
    color: white;
}
</style>
      