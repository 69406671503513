<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark" aria-label="Tenth navbar example">
    <div class="container-fluid">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample08"
        aria-controls="navbarsExample08" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse justify-content-md-center" id="navbarsExample08">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link to="/shipments-progress" class="nav-link active" aria-current="page">Accepted</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/shipments-pending" class="nav-link active" aria-current="page">Pending</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/shipments-delivered" class="nav-link active" aria-current="page">Delivered</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/users" class="nav-link active" aria-current="page">Users</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/service-points" class="nav-link active" aria-current="page">Service-points</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/statistics" class="nav-link active" aria-current="page">Statistics</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/invoices" class="nav-link active" aria-current="page">Invoices</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/refunds" class="nav-link active" aria-current="page">Refund</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/clients" class="nav-link active" aria-current="page">Clients</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/shipments-progress" class="nav-link active" aria-current="page"><button
                class="btn btn-danger" @click="logout()" style="padding: 2px;">Log out</button></router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import axios from 'axios'
import en from '../en.js';
import fr from '../fr.js';
import nl from '../nl.js';



export default {
  mixins: [en, fr, nl],
  data() {
    return {
      orders: [],
      lang: this.$store.state.lang,
    }
  },
  computed: {
    lang2() {
      return this.$store.state.lang
    },
  },
  mounted() {
    const language = navigator.language.split('-')[0];
    const supportedLanguages = ['en', 'fr', 'nl'];

    if (supportedLanguages.includes(language)) {
      this.lang = language;
    } else {
      this.lang = 'en';
    }

    this.updateLang()

  },
  methods: {
    translate(prop) {
      return this[this.lang2][prop];
    },
    updateLang() {
      this.$store.commit('setLang', this.lang);
    },
    logout() {
      axios.defaults.headers.common["Authorization"] = ""
      sessionStorage.removeItem("token")
      this.$store.commit('removeToken')
      this.$store.commit('clearShipment')
      this.$router.push('/login')
    }
  }
}
</script>




<style>
.iteNav {
  font-weight: bold;
  font-size: 17px;
  color: black;
}

.iteNav:hover {
  border-bottom: 4px solid #E5097F;
  padding-bottom: 1px;
  /* Adjust this value to change the space between the word and the underline */
  display: inline-block;
  /* This is needed to make padding apply correctly for inline elements */

}




.btn-login {
  color: white;
  background-color: #E5097F;
  font-weight: 600;
  border-color: #E5097F;
  padding: 8px 12px 8px 16px;
  gap: 8px;
  height: 40px;
  width: 128px;
  border: none;
  border-radius: 20px;
}

.btn-login:hover {
  color: white;
  background-color: #9B0656;
}

.btn-signup {
  color: white;
  background-color: #9B0656;
  font-weight: 600;
  border-color: #9B0656;
  padding: 8px 12px 8px 16px;
  gap: 8px;
  height: 40px;
  width: 128px;
  border: none;
  border-radius: 20px;
}

.btn-signup:hover {
  color: white;
  background-color: #E5097F;
}

.btn-logout {
  color: white;
  background-color: #dc3545;
  font-weight: 600;
  border-color: #9B0656;
  padding: 8px 12px 8px 16px;
  gap: 8px;
  height: 40px;
  border: none;
  border-radius: 20px;
}

.btn-logout:hover {
  color: white;
  background-color: rgb(175, 12, 12);
}</style>
