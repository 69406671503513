export default {
    data() {
        return {
            nl: {
                shipping_route: "Verzendroute",
                ship_from: "Verzenden vanuit",
                ship_to: "Verzenden naar",
                postal_code: "Postcode",
                choose_country: "Kies een land",
                city: "Stad",
                shipping_date: "Verzenddatum",
                choose_package: "Kies uw maat",
                belgium: "België",
                other_size: "Andere grootte",
                weight: "Gewicht (KG)",
                width: "Breedte (cm)",
                height: "Hoogte (cm)",
                length: "Lengte (cm)",
                rates_times: "Tarieven en Tijden",
                delivery_date: "Leveringsdatum",
                package_type: "Pakkettype",
                price: "Prijs (EUR)",
                order: "Bestelling",

                alert1: 'Kies alstublieft het leveringsland',
                alert2: 'Kies alstublieft de leveringspostcode',
                alert3: 'Kies alstublieft de leveringsstad',
                alert4: 'Kies alstublieft de ophaalpostcode',
                alert5: 'Kies alstublieft de ophaalstad',
                alert6: 'Kies alstublieft de verzenddatum',
                alert7: 'heeft geen postcodesysteem',
                alert8: 'Vul alstublieft de voornaam in',
                alert9: 'Vul alstublieft de achternaam in',
                alert10: 'Vul alstublieft het huisnummer in',
                alert11: 'Vul alstublieft de straat in',
                alert12: 'Vul alstublieft de postcode in',
                alert13: 'Vul alstublieft de stad in',
                alert14: 'Vul alstublieft de provincie in',
                alert15: 'Vul alstublieft het e-mailadres in',
                alert16: 'Voer alstublieft een geldig e-mailadres in',
                alert17: 'Vul alstublieft het telefoonnummer in',
                alert18: 'Vul alstublieft de beschrijving van uw pakket in',
                alert19: 'Kies een servicepunt alstublieft',
                alert20: 'Bevestig alstublieft de veilige verpakking voordat u verder gaat',
                alert21: 'Vul de benodigde velden voor de douane in!',
                alert22: 'Vul alstublieft de noodzakelijke velden in!',
                alert23: 'Promocode is geldig, bedrag: ',
                alert24: 'De promocode is niet geldig!',
                alert25: 'Kon geen statistieken ophalen !!',
                alert26: "De afmetingen zijn te groot!",
                alert27: 'Kies uw ophaal locatie',

                summary: "Samenvatting",
                next: "Volgende",
                total_amount: "Totaal bedrag",
                including_vat: "inclusief BTW",

                sender_information: 'Afzendergegevens',
                first_name: "Voornaam",
                last_name: "Achternaam",
                email: "E-mail",
                phone: "Telefoon",
                search_address: "Zoek op Google Maps",
                number: "Nummer",
                street: "Straat",
                state: "Staat",
                shipping_route_2: "Verzendroute (Naar",
                no_insurance: "Geen verzekering",
                assurance: "Verzekering",

                receiver_information: "Ontvanger gegevens",

                //zending Informatie
                shipment_info: "Zending Informatie",
                address: "Adres",
                search: "Vinden",
                enter_address: "Voer alstublieft uw adres in",
                choose_service_point: "Zoek de dichtstbijzijnde servicepunten in jouw omgeving :",
                placeholder_service_point: "Kies een servicepunt om uw pakket af te geven",
                description: "Beschrijving",
                placeholder_description: "Schrijf hier een beschrijving van uw pakket...",
                text: "Let op: Door dit aan te vinken, bevestig ik dat de verpakking veilig is voor een veilig transport en erken ik mijn verantwoordelijkheid.",
                assurance_text1: "Verzeker mijn pakket.",
                assurance_text2: " (Houd er rekening mee dat de maximale verzekerde waarde voor zendingen 1000€ is)",
                commercial_text: "Heeft u iets om aan te geven bij de douane?",
                nature: "Aard",
                n_items: "Aantal items",
                unit_price: "Eenheidsprijs (€)",
                hs_code: "HS code (optioneel)",
                remark: "Opmerking",

                //validatie
                validate_info: "Informatie valideren",
                max_weight: "Maximaal gewicht",
                service_point: "Servicepunt",
                pickup_address: "Ophaaladres",
                insure_my_parcel: "Pakket verzekering",
                commercial_value: "Commerciële waarde",
                shipment_price: "Verzendprijs",
                nature_package: "Aard van het pakket",
                edit: "Bewerken",
                number_items: 'Aantal items',
                country: "Land",
                vat: "BTW",
                proceed_payment: "Doorgaan naar betaling",

                //betaling
                payment_method: "Betalingsmethode",
                pay_credit_card: "Betalen met creditcard",
                pay_bancontact: "Betalen met Bancontact",
                pay_cash: "Betalen met contant geld",
                payment_success: "Betaling geslaagd",
                payment_success_cong: "Gefeliciteerd! Uw betaling is succesvol verwerkt",
                payment_failed: "Betaling mislukt",
                payment_failed_txt: "Oeps! Er was een probleem bij het verwerken van uw betaling. Probeer het later opnieuw",

                //betaling creditcard
                credit_card: "Creditcardbetaling",
                enter_promotion_code: "Voer promotiecode in",
                promo_code: "Promotiecode",
                enter_promotion_code_place: "Voer de promotiecode in",
                validate_code: "Code valideren",
                card_inf: "Kaartinformatie",
                discount: "Korting",

                bancontact: "Bancontact Betaling",

                //Contante betaling
                cash: "Contante betaling",
                verification_code: "Verificatiecode",
                verification_code_place: "Voer de verificatiecode in",
                validate_payment: "Betaling valideren",
                verification_code_failed: "De verificatiecode is niet geldig!",
                verification_code_txt: 'De code is verzonden naar het e-mailadres van het servicepunt dat u heeft gekozen. Neem contact met hen op om de code te ontvangen!',

                //Hoofdmenu
                home: "Startpagina",
                new_shipment: "Nieuwe zending",
                track_shipment: "Zending volgen",
                login: "Inloggen",
                sign_up: "Anmelden",
                myprofile: "Mijn profiel",
                sign_out: "Uitloggen",
                english: "Engels",
                french: "Frans",
                dutch: "Nederlands",

                //Agentmenu
                dashboard: "Dashboard",
                //Deelmenu
                my_orders: "Mijn bestellingen",

                //Dashboard
                hello: "Hallo",
                welcome_back: "Welkom terug!",
                tracking_number: "Volgnummer",
                order_date: "Besteldatum",
                without_vat: "zonder BTW",
                payment: "Betaling",
                commissions: "Commissies",
                status: "Status",
                not_paid: "Niet betaald",
                paid: "Betaald",
                collected: "Geïnd",
                cashed: "In contanten",
                credit_card_s: "Creditcard",
                cash_s: "Contant geld",
                bancontact_s: "Bancontact",
                orders: "Bestellingen",
                my_company: "Mijn bedrijf",
                support: "Ondersteuning en assistentie",
                company_name: "Bedrijfsnaam",
                vat_number: "BTW-nummer",
                commission: "Commissie",
                today_shipment: "Zendingen van vandaag",
                shipment_progress: "Zendingen in uitvoering",
                shipment_deleivred: "Afgeleverde zendingen",
                all_shipment: "Alle zendingen",
                shipments: "Zendingen",
                orders_overview: "Bestellingsoverzicht:",
                profit_this_mounth: "Uw winst deze maand",
                shipments_amount: "Geld verzameld",
                total: "Totaal",
                pay_bank: "Betalen via bankoverschrijving",
                pay_cash: "Betalen met contant geld",
                see_more: "Meer bekijken",
                withdraw_money: "Geld opnemen",
                in_progress: "In uitvoering",
                delivered: "Afgeleverd",
                my_commissions: "Mijn commissies",
                bank_alert: "Betaling via bankoverschrijving",
                bank_alert_txt: "Het verzoek is succesvol naar de administratie verzonden. We zullen binnenkort contact met u opnemen!",
                cash_alert: "Betaling met contant geld",
                cash_alert_txt: 'Het verzoek is succesvol naar de administratie verzonden. We zullen binnenkort contact met u opnemen!',
                get_money: "Geld opnemen",
                get_money_txt: 'Het verzoek is succesvol naar de administratie verzonden. We zullen binnenkort contact met u opnemen!',

                //Inlogpagina
                forgot_password: "Wachtwoord vergeten?",
                dont_have_account: "Heeft u nog geen account?",
                have_account: "Heeft u al een account?",
                reset_password: "Wachtwoord opnieuw instellen",
                login_error: "E-mail of wachtwoord is onjuist",
                password: "Wachtwoord",
                confirm_password: "Bevestig wachtwoord",

                no_orders: "Op dit moment zijn er geen bestellingen op uw account",

                Yes: "Ja",
                No: "Nee",

                //Profiel
                title: "Titel",
                last_login: "Laatste login",
                mr: "Dhr",
                mrs: "Mevr",
                ms: "Mevr",
                update: "Bijwerken",
                update_alert: "Succesvol bijgewerkt!",
                error: "Er is iets misgegaan. Probeer het opnieuw",
                update_title: "Informatie bijwerken",
                update_txt: 'Weet u zeker dat u uw informatie wilt bijwerken?',
                update_now: "Nu bijwerken",
                cancel: "Annuleren",

                sender: "Afzender",
                shipping_label: "Verzendlabel",

                new_password: "Nieuw wachtwoord",
                confirm_new_password: "Bevestig nieuw wachtwoord",

                reset_password_alert: "Wachtwoord bijgewerkt!",

                send_email: "E-mail verzenden",
                send_email_alert: 'E-mail voor het opnieuw instellen van het wachtwoord verzonden!',

                track: 'Volgen',
                nom: "Naam",
                shipping_history: "Verzendgeschiedenis",
                receiver: "Ontvanger",
                date: 'Datum',
                location: "Locatie",
                track_title: 'Ongeldig volgnummer',
                track_txt: 'Ongeldig volgnummer. Probeer het opnieuw. Bedankt!',

                sender_information2: "Afzender",
                receiver_information2: "Ontvanger",
                shipment_info2: "Zending",


                register_mssg: "Account aangemaakt, log in!",


                // waarschuwing verzekering
                important_notice: "Belangrijke Mededeling",
                alert_assurance_message: "Als u geen verzekering kiest, komt u niet in aanmerking voor een terugbetaling bij verlies of schade.",
                add_assurance: "Verzekering toevoegen",
                without_assurance: "Doorgaan zonder verzekering",











                //alerte envoi instance d'expédition
                alert_shipmentinstance: "We hebben de betalingslink naar de klant gestuurd",
                alert_shipmentinstance_message: "Controleer de volgende e-mail om door te gaan met de betaling : ",
                send_payment_link: "Betaallink versturen",

                create_new_meeting: "Nieuwe vergadering maken",
                join_your_meeting: "Neem deel aan uw vergadering",

                //succes
                cong: "Gefeliciteerd!",
                package_safe_hands: "Uw pakket is nu in goede handen.",
                once_your_package: "U kunt uw pakket volgen door op de knop hieronder te klikken:",
                track_your_package: "Volg uw pakket met twee handige opties :",
                thr_whatsapp: "Via WhatsApp",
                thr_sms: "Via SMS",
                thr_website: "Op onze website",
                whts_desc: "Stuur ons alstublieft uw volgnummer via WhatsApp naar:",
                provide_infor: "Wij zullen u real-time updates verstrekken over de status van uw pakket.",
                sms_desc: "Stuur ons alstublieft uw volgnummer via een sms-bericht naar:",
                website_desc: "Om uw pakket te volgen, voer alstublieft het volgnummer in op onze volgpagina.",
                your_satistfaction: "Aarzel niet om contact met ons op te nemen als u verdere assistentie nodig heeft. Uw tevredenheid is onze prioriteit!",

                business_day: "werkdag",
                delivery_time: "Levertijd",

                your_address: "Jouw adres",
                service_point_address: "Adres servicepunt",
                open_google_maps: "Openen in Google Maps",

                welcome_message: "De wereld ligt aan uw deur met een klik!",
                sous_welcome_message: "Welkom in het nieuwe tijdperk van bezorging met Eurêkali",

                change_myAddresse: "Geen servicepunt in de buurt? Wijzig uw adres",

                //footer
                about_us: "Over ons",
                terms_of_use: "Gebruiksvoorwaarden",
                privacy_policy: "Privacybeleid",
                accessibility_statement: "Toegankelijkheidsverklaring",
                all_rights_resereved: "Alle rechten voorbehouden",

                home_collection: "Thuis ophalen",
                servicepoint_collection: "Eurekali servicepunt",
                servicepoint_collection_text: "Lever het in bij een Eurekali-punt",
                home_collection_text: "Haal het pakketje bij mij op",
                drop_off_option: "Kies de ophaallocatie :",

                your_feedback: "Schrijf alstublieft uw feedback...",
                send: "Verzenden",
                give_feedback: "Geef ons alstublieft feedback",
                thanks_feedback: "Dank u voor uw feedback",
                how_was_your_experience: "Hoe was uw ervaring met het nieuwe Eurêkali-systeem?",

                call_us : "Bel ons",
                click_here: "Klik hier als u hulp nodig heeft",

                // Startpagina agent
                join_us: "Doe mee",
                become_agent: "Word vandaag een Eurekali-agent en ontdek hoe uw partnerschap uw bedrijf naar succes kan brengen!",
                why_agent: "Waarom zijn onze agenten blij om een Eurekali-punt te zijn?",
                parg1_title: "1 - Trek meer klanten aan",
                parg1: "Word een Eurekali-servicepunt en verander uw winkel in een activiteitencentrum voor degenen die iets moeten verzenden. Dit is een geweldige kans om het verkeer te vergroten en nieuwe mensen kennis te laten maken met uw bedrijf.",
                parg2_title: "2 - Verhoog uw inkomen",
                parg2: "Als partner van Eurêkali kunt u voor elk pakket dat u beheert, een commissie van 10% verdienen (€1,8 tot €32 per zending). Het is een eenvoudige en effectieve manier om een nieuwe inkomstenbron aan uw bedrijf toe te voegen.",
                parg3_title: "3 - Verbeter uw klantenservice",
                parg3: "Het aanbieden van Eurêkali-diensten betekent het leven van uw klanten gemakkelijker maken. Of ze nu nationaal of internationaal pakketten willen verzenden of ontvangen, ze kunnen het doen zonder uw winkel te verlaten. Een goede klantenservice betekent vaak loyale klanten!",
                parg4_title: "4 - Versterk uw reputatie",
                parg4: "Samenwerken met Eurekali, een gerespecteerd merk in de verzendindustrie, kan de geloofwaardigheid van uw winkel vergroten en het vertrouwen van uw klanten versterken. Toon dit partnerschap met trots en geniet van de voordelen die het kan bieden voor uw imago.",
                register: "Registreer",

                //Aanmelden Agent
                personal_information : "Persoonlijke informatie :",
                company_information: "Bedrijfsinformatie :",
                activity: "Activiteit",
                store_name: "Winkelnaam",

                question_promo_code : "Heb je een promotie code ?",
                sale_ticket : "Verkoop kaartje",

                shipments_to_validate : "Lopende zendingen",
                proforma_invoice : "Proforma invoice",
                validate: "Valideer",
                shipment_validated: "De zending is gevalideerd",
                question_validation: "Weet u zeker dat u deze zending wilt valideren?",
                shipment_validation : "Verzendings validatie",
                modify : "Bewerken",

                paid_price : "Betaalde prijs",
                price_to_pay : "Prijs te betalen",
                validate_payment : "Valideer de betaling",
                package_description: "Pakket beschrijving",
                other_cash: "Contante aanvulling",
                commission_to_pay : "Commissions to pay",
                no_codes : "Op dit moment zijn er geen verzoeken voor verificatiecodes voor contante betalingen.",
                client : "Klant",
                verification_code : "Verificatiecode",
                amount : "Bedrag",
                request_date : "Aanvraagdatum",
                cancel : "Annuleren",

                my_outstandings: "Mijn openstaande bedragen:",
                my_outstandings_text1: "• Uw commissie wordt automatisch toegevoegd, zelfs als de klant rechtstreeks op onze site bestelt, met een kaart betaalt en het pakket in uw winkel aflevert.",
                my_outstandings_text2: "• Facturering wordt automatisch om de 15 dagen uitgevoerd.",
                total_to_pay: "Totaal te betalen",
                total_to_be_reimbursed: "Totaal te vergoeden",
                invoice_request: "Factuur aanvragen",
                request_refund: "Terugbetaling aanvragen",
                invoice_request_text: "Weet u zeker dat u een factuur wilt aanvragen?",
                request_refund_text: "Weet u zeker dat u een terugbetaling wilt aanvragen?",
                invoicev2_created_success: "De factuur is succesvol gegenereerd",
                refund_created_success: "Terugbetaling is aangevraagd",
                my_bills: "Mijn facturen:",
                invoice_number: "Referentienummer",
                invoice_date: "Factuurdatum",
                amounts: "Bedragen",
                status: "Status",
                invoice_in_pdf: "Afdrukken",
                my_refunds: "Mijn terugbetalingen:",
                pay: "Betalen",
                invoice_payment: "Factuurbetaling",
                invoice_payment_text: "Kies alstublieft uw betalingstype",
                pay_with_card: "Betalen met kaart",
                pay_with_cash: "Betalen met contant geld",

                registration: "Registratie",
                registration_text: "Wilt u een nieuw account aanmaken als een?",
                agent: "Agent",
                individual: "Particulier",

                details : "Details",
                see_details : "Zie de details",
                payment_type : "Betalingswijze",
                
                printer_text: "Heeft u een printer waarmee u het verzendlabel kunt maken?",
                printer: "Printer",
                read_conditions_alert: "Voordat u verder gaat, moet u uw acceptatie van de algemene gebruiksvoorwaarden bevestigen.",
                safe_parcel_alert: "Bevestig alstublieft dat uw zending geen gevaarlijke goederen bevat",
                safe_parcel_text: "Ik verklaar dat mijn zending geen gevaarlijke goederen bevat:",
                safe_parcel_text_sntc1: "Noch enige goederen die verboden zijn door de vervoerder of het land van bestemming.",
                safe_parcel_text_sntc2: "Zie de lijst van verboden goederen.",
                safe_parcel_text_sntc3: "Elke niet-conforme zending kan aansprakelijkheid met zich meebrengen en de levering van uw pakket blokkeren.",
                safe_parcel_text_sntc4: "Uw zending zal geen recht geven op enige compensatie.",
                read_conditions_text_sntc1: "Ik accepteer",
                read_conditions_text_sntc2: "de algemene voorwaarden voor het gebruik van de website eurekali.com",
                read_conditions_text_sntc3: "evenals",
                read_conditions_text_sntc4: "de algemene voorwaarden van de vervoerder.",

                packaging: "Verpakking",
                packaging_text: "Door dit aan te vinken, bevestig ik dat ik de verpakking heb beveiligd voor een veilig transport en aanvaard ik mijn verantwoordelijkheid.",
                important_note: "Belangrijke opmerking: ",
                phonenumver_verify_text: "Controleer alstublieft uw telefoonnummer, want zo ontvangt u de informatie over uw zending.",
                sendsms: "Bevestigen",
                sms_confirmation: "Stuur bevestiging per SMS",
                sms_sent_text: "Uw verzendinformatie is succesvol via SMS naar uw telefoonnummer verstuurd.",
                dont_receive_sms: "Ik heb de SMS niet ontvangen",

                pickup_location : "Ophaal locatie",
                thank_you : "Bedankt!",
                we_will_call_you : "Wij bellen u binnen enkele ogenblikken",
                call_me : "Bel me",

                users_review_text1: "De meningen van onze klanten over Eurekali",
                users_review_text2: "Vertrouwen en tevredenheid!",

                customs_declaration : "Douane aangifte :",

                // Productenpagina
                products_page_title : "Verboden, gereguleerde en niet aanbevolen producten voor import- en exportoperaties.",
                products_paragraphe : "Om uw zending te vereenvoudigen, hebben we belangrijke producten vermeld die verboden zijn of aan regelgeving onderhevig zijn (let op dat deze lijst niet allesomvattend is). Voordat u een zending verzendt, raden we aan de voorwaarden van de vervoerder te controleren en ervoor te zorgen dat uw pakket geen enkel risico met zich meebrengt.",
                products_text1 : "Maak uw verzending eenvoudiger:",
                products_text2 : "Hier is een essentiële regelgeving om rekening mee te houden:",
                products_text3 : "Tegenwoordig hangt het verbod of de regulering van een product af van verschillende factoren, waaronder de vervoerswijze, internationale voorschriften, de kenmerken van het land van herkomst of bestemming, de regels van de vervoerder en de aard van het product zelf. Het doel is potentiële risico's tijdens het transport te voorkomen, mensen, eigendommen en het milieu te beschermen, en een internationale norm te waarborgen voor het vervoer van goederen tussen landen.",
                products_text4 : "Het is belangrijk op te merken dat het verzenden van verboden goederen de afzender blootstelt aan strafrechtelijke vervolging in geval van materiële of lichamelijke schade tijdens het transport. Bovendien kan de vervoerder ervoor kiezen de goederen te blokkeren of vernietigen, zonder mogelijkheid van claims of vergoedingen.",
                products_text5 : "Verboden goederen omvatten onder andere:",
                products_text6 : "Explosieve, gevaarlijke, giftige, ontvlambare, bijtende of radioactieve producten zoals handdesinfectiemiddelen, ontsmettingsmiddelen, brandstoffen, chemicaliën, wapens, verdovende middelen, enz.",
                products_text7 : "Nat verpakte goederen die lekken of geuren afgeven.",
                products_text8 : "Producten onder druk, waaronder spuitbussen, gasflessen en zuurstofcilinders.",
                products_text9 : "Vuurwapens, munitie en militaire uitrusting.",
                products_text10 : "Lithium-ion en lithium-metaal batterijen, evenals elektrische voertuigen.",
                products_text11 : "Lichamen, organen, biologische materialen en medisch afval.",
                products_text12 : "Medicijnen, zelfs met een recept.",
                products_text13 : "Producten gerelateerd aan wilde dieren en flora, planten, levende of dode dieren, enz.",
                products_text14 : "Geld en waardevolle spullen zoals munten, sieraden, edelmetalen, enz.",
                products_text15 : "Tabaksproducten, inclusief elektronische sigaretten.",
                products_text16 : "Bederfelijke voedingsmiddelen en producten die temperatuurbeheersing vereisen.",
                products_text17 : "Vervalsingen, illegale of immorele producten.",
                products_text18 : "Polycarbonaat babyflessen met bisfenol A.",
                products_text19 : "Zendingen met een cilindrische of kegelvorm, zoals tapijten of matrassen.",
                products_text20 : "Andere producten met dit type symbool:",
                products_text21 : "Niet aanbevolen goederen omvatten onder andere:",
                products_text22 : "Fragiele items of items met een niet-standaard verpakking.",
                products_text23 : "Alcoholische dranken (beperkt tot professionele klanten met goedgekeurde specifieke verpakking).",
                products_text24 : "Huishoudelijke apparaten zoals ovens, wasmachines, vaatwassers, enz.",
                products_text25 : "Materialen zoals keramiek, marmer, glas, enz.",
                products_text26 : "Muziekinstrumenten, elektronische apparatuur, decoratieve items, auto-accessoires, enz.",
                products_text27 : "Het is essentieel om deze regelgeving in overweging te nemen om een veilige verzending te waarborgen in overeenstemming met wetten en internationale normen.",

                //Paginavoorwaarden
                conditions_page_title: "Algemene Voorwaarden voor het Gebruik van de Website",
                conditions_definitions: "1. Definities:",
                conditions_text1: "In het kader van deze gebruiksvoorwaarden zal de volgende terminologie worden gebruikt:",
                conditions_text2: '"Gebruiker" verwijst naar elke natuurlijke of rechtspersoon die de diensten wil gebruiken die worden aangeboden door de vervoerders die op de website staan vermeld om te vergelijken, te boeken, te bestellen en/of te kopen, of iemand te verzoeken om te bestellen en/of te kopen bij een vervoerder die op de site wordt vermeld.',
                conditions_text3: '"Vervoer" verwijst naar elke handeling, vanaf het moment dat de vervoerder de goederen in ontvangst neemt tot de levering op de bestemming, wanneer een vertegenwoordiger van het aankopende bedrijf of een andere persoon aanwezig is. Het verwijst ook naar eventuele aanvullende diensten of opties die door de vervoerder worden aangeboden, zoals pakketverzekering of betaling onder rembours.',
                conditions_text4: '"Vervoerder" verwijst naar elke vervoerder die de diensten aanbiedt die op de website worden verstrekt. Hieronder vallen ook postbedrijven, internationale en nationale vrachtintegratoren, stads- en interstedelijke koeriers, gespecialiseerde express bezorgbedrijven, bedrijven die ophaalpunten voor bezorging aanbieden, en lokale of regionale bedrijven.',
                conditions_text5: '"Vervoerder" verwijst ook naar het externe bedrijf dat door EUREKALI is aangesteld om de ophaling van het pakket te verzorgen, in overeenstemming met deze gebruiksvoorwaarden en de algemene voorwaarden van de geselecteerde vervoerder.',
                conditions_text6: "2. Doel en Toepassing van de Voorwaarden:",
                conditions_text7: 'Tenzij anders schriftelijk vermeld, zijn deze algemene gebruiksvoorwaarden (hierna aangeduid als "de Voorwaarden") van toepassing op alle gebruikers van de website www.eurekali.be (hierna aangeduid als "de Site").',
                conditions_text8: 'De aankoopdiensten zijn alleen toegankelijk voor gebruikers die de Voorwaarden, het document getiteld "Mijn Verzendgids" en de specifieke voorwaarden van de vervoerders voor elke dienst volledig hebben gelezen en geaccepteerd voordat ze een bestelling plaatsen.',
                conditions_text9: 'EUREKALI behoudt zich het recht voor om de Voorwaarden op elk moment en zonder voorafgaande kennisgeving te wijzigen. Het is begrepen dat dergelijke wijzigingen niet van toepassing zijn op eerder aanvaarde bestellingen.',
                conditions_text10: 'De Site is een tool waarmee gebruikers transporttarieven kunnen vergelijken die worden aangeboden door vervoerders tegen preferentiële tarieven.',
                conditions_text11: 'De Gebruiker is vrij om de Vervoerder te kiezen waarmee hij wil samenwerken.',
                conditions_text12: 'De Site wordt gepubliceerd door de besloten vennootschap EUREKALI, met haar maatschappelijke zetel gevestigd aan de 16b Rue des Trois Arbres, 1180 Uccle (België), en ingeschreven in het Belgisch Register van Rechtspersonen (BCE) onder nummer 0537.251.722. Telefoonnummer: +32 2 333 37 77, e-mail: info@eurekali.com.',
                conditions_text13: '3. Voorafgaande Informatie en Disclaimer Clausule:',
                conditions_text14: 'Om toegang te krijgen tot de site moet de Gebruiker beschikken over een internetverbinding en de benodigde apparatuur. De verbindingskosten zijn voor rekening van de Gebruiker.',
                conditions_text15: 'EUREKALI garandeert niet de continuïteit van de toegang tot de Site of tot een van de diensten van de vervoerders (behalve voor lopende bestellingen) en kan niet aansprakelijk worden gesteld voor de beschikbaarheid van de Site of de diensten van de vervoerder. Op elk moment kan EUREKALI besluiten de Site bij te werken om de werking te verbeteren, geheel of gedeeltelijk de Site en de inhoud ervan te wijzigen om deze responsiever te maken voor de eisen van de Gebruiker, eventuele fouten op de Site te corrigeren of onderhoudswerkzaamheden uit te voeren aan de servers van EUREKALI die de toegang tot de Site of enige transportdienst kunnen verstoren.',
                conditions_text16: "EUREKALI kan niet aansprakelijk worden gesteld voor de onmogelijkheid van de Gebruiker om toegang te krijgen tot de Site als gevolg van voornoemde werkzaamheden of om welke reden dan ook buiten haar controle. EUREKALI kan niet aansprakelijk worden gesteld voor een onderbreking van de verbinding van de Gebruiker op elk moment of voor een storing buiten haar controle die de Gebruiker kan verhinderen om bepaalde handelingen uit te voeren.",
                conditions_text17: "In het geval dat de storing wordt veroorzaakt door de werking van de Site, zal de Gebruiker erkennen dat technisch gezien het goede functioneren van de Site afhankelijk kan zijn van factoren die niet volledig onder de controle van EUREKALI vallen.",
                conditions_text18: "EUREKALI kan niet garanderen dat de site vrij is van computervirussen of andere anomalieën buiten haar controle. Desalniettemin zal EUREKALI alle mogelijke maatregelen nemen om de Gebruikers een veilig gebruik van de Site te garanderen.",
                conditions_text19: "Daarom kan EUREKALI niet aansprakelijk worden gesteld voor enige schade of verlies als gevolg van systeemfouten of onderbrekingen die van invloed zijn op het juiste functioneren van de Site en de online weergave van de diensten van de vervoerder.",
                conditions_text20: "Bovendien behoudt EUREKALI zich het recht voor om de toegang tot de Site op elk moment en zonder voorafgaande kennisgeving te weigeren in geval van onrechtmatig gebruik of gebruik in strijd met de Voorwaarden.",
                conditions_text21: "4. Gebruik van de Site:",
                conditions_text22: "EUREKALI biedt uitsluitend via haar Site een geautomatiseerde vergelijkingsservice voor gedetailleerde transporttarieven van goederen (met uitzondering van verhuisservices) die worden aangeboden door de door haar geselecteerde vervoerders, op niet-exclusieve en niet-uitputtende wijze.",
                conditions_text23: 'De volledige stappen voor het bestellen van de dienst worden uitgelegd in het document "Mijn Verzendgids", dat de Gebruiker erkent na lezing en acceptatie.',
                conditions_text24: "Het transportcontract wordt rechtstreeks gesloten tussen de Gebruiker en de Vervoerder. EUREKALI factureert de Gebruiker voor het volledige scala aan diensten.",
                conditions_text25: 'EUREKALI is gemachtigd door het bedrijf CIT Express (met maatschappelijke zetel gevestigd aan 16b Rue des Trois Arbres, 1180 Uccle, België, en ingeschreven in het BCE onder nummer 0537.251.722) om namens CIT Express, onder de Voorwaarden, het gratis mandaat te sluiten waarmee CIT Express op zijn beurt elk transportcontract zal sluiten tussen de vervoerder die door de Gebruiker is gekozen en de Gebruiker, namens en voor rekening van wie CIT Express zal optreden (onder het gratis mandaat gegeven door de Gebruiker, dat op zijn beurt is gesloten door EUREKALI als gemachtigde van CIT Express, overeenkomstig het bovenstaande).',
                conditions_text26: 'EUREKALI is ook gemachtigd door CIT Express om ervoor te zorgen dat eventuele facturen aan de Gebruiker worden uitgegeven tegen de door de Gebruiker gekozen prijs en om de verschuldigde bedragen te innen. Mits volledige betaling van de bedragen verschuldigd door de Gebruiker, garandeert EUREKALI betaling aan de Vervoerder door de Gebruiker.',
                conditions_text27: "EUREKALI vestigt de aandacht van de Gebruiker op de algemene voorwaarden van de geselecteerde Vervoerder die van toepassing zijn op hun bestelling. De voorwaarden van de vervoerders zijn te vinden (hier). De Gebruiker stemt in met de naleving van deze voorwaarden, met name met betrekking tot de aansprakelijkheidsbeperkingen met betrekking tot transportdiensten, aangezien EUREKALI in dit opzicht geen aansprakelijkheid heeft.",
                conditions_text28: "De Gebruiker doet uitdrukkelijk afstand van enige juridische of buitengerechtelijke actie, vordering, in garantie, of tegenactie, rechtstreeks of onrechtstreeks, tegen EUREKALI met betrekking tot de diensten van de vervoerders.",
                conditions_text29: "De Gebruiker erkent dat de ophaling van de zending kan worden uitgevoerd door een extern bedrijf om redenen van efficiëntie, zonder dat EUREKALI enige verantwoordelijkheid aanvaardt anders dan die is beschreven in de Voorwaarden of de algemene voorwaarden van de geselecteerde Vervoerder.",
                conditions_text30: "De Gebruiker doet afstand van het recht om te vertrouwen op enig document dat aanvullend of tegenstrijdig kan zijn aan de Voorwaarden of de algemene voorwaarden van de vervoerders.",
                conditions_text31: "EUREKALI kan de Gebruiker niet garanderen dat het aanbod van transport op de site bestaat, nauwkeurig is of beschikbaar is. De aanbiedingen worden aan EUREKALI verstrekt onder de uitsluitende verantwoordelijkheid van de vervoerders en zijn afhankelijk van de acceptatie van de bestellingen door de vervoerder.",
                conditions_text32: "5. Klantaccount:",
                conditions_text33: 'De Gebruiker kan een "klantaccount" aanmaken, hoewel dit niet verplicht is om de Site te gebruiken.',
                conditions_text34: "Om een klantaccount aan te maken, moet de Gebruiker een geldig e-mailadres en een wachtwoord opgeven. De Gebruiker is verantwoordelijk voor de vertrouwelijkheid van zijn gebruikersnaam en wachtwoord, evenals voor eventuele acties die vanuit zijn account kunnen worden ondernomen met behulp van zijn gebruikersnaam en/of wachtwoord.",
                conditions_text35: "De Gebruiker stemt uitdrukkelijk in met onmiddellijke melding aan EUREKALI van elk ongeautoriseerd gebruik van zijn account en/of wachtwoord en/of enig probleem dat zijn beveiliging kan verstoren. EUREKALI kan onder geen enkele omstandigheid aansprakelijk worden gesteld voor enig verlies of schade als gevolg van de schending van verplichtingen in dit artikel.",
                conditions_text36: "6. Wijziging en Annulering van de Bestelling door de Gebruiker:",
                conditions_text37: "Na bevestiging van de bestelling op de site is annulering of wijziging niet mogelijk. De Gebruiker erkent dat de wettelijke bedenktijd van 14 dagen zoals voorzien in de Belgische wet van 6 april 2010 inzake marktpraktijken en consumentenbescherming niet van toepassing is op bestellingen die onder deze Voorwaarden vallen.",
                conditions_text38: "7. Tarieven en Prijzen:",
                conditions_text39 : "De tarieven aangeboden door de vervoerders zijn vermeld in euro's, inclusief alle rechten en belastingen, en kunnen op elk moment zonder voorafgaande kennisgeving worden gewijzigd. Alleen de prijs die is bevestigd op het moment van de bestelling is geldig.",
                conditions_text40 : "De prijzen van de transportdiensten die via de website worden aangeboden, zijn echter niet onderhevig aan wijziging nadat de bestelling is bevestigd.",
                conditions_text41 : "In het algemeen, en tenzij anders vermeld, is enige andere dienst die niet uitdrukkelijk wordt vermeld in de orderbevestiging niet inbegrepen in de prijs. Evenzo zijn eventuele van toepassing zijnde kosten en belastingen van welke aard dan ook de verantwoordelijkheid van de gebruiker of de bestemmingscontactpersoon, overeenkomstig de geldende voorschriften.",
                conditions_text42 : "Het is daarom de verantwoordelijkheid van de gebruiker om informatie in te winnen over rechten en belastingen, inclusief douanerechten, die van toepassing kunnen zijn op de zending, aangezien dergelijke informatie niet kan worden verstrekt aan de gebruiker als onderdeel van de bestelling.",
                conditions_text43 : 'In het geval dat er een extra betaling moet worden toegepast voor eventuele extra kosten die tijdens het bestelproces worden gemaakt, zoals vermeld in de "aanvullende kosten" sectie van "Mijn verzendgids", wordt de gebruiker rechtstreeks per e-mail op de hoogte gesteld. De bestelling wordt opgeschort totdat de betaling van de prijstoeslag is voltooid.',
                conditions_text44 : "In het geval dat het gewicht van het pakket aanzienlijk hoger is dan tijdens de bestelling gecommuniceerd, worden boetes berekend volgens de onderstaande tabel. Als het gewicht en het volume hoger zijn dan tijdens de bestelling gecommuniceerd, moet de zwaarste boete van de twee worden toegepast.",
                conditions_text45 : "Download alle algemene voorwaarden en servicevoorwaarden van de website in PDF-formaat.",

                send_my_parcel: "Stuur mijn pakket",
                dimensions_alert: "Voer de afmetingen van uw pakket in",
                verify_postalcode: "Controleer of de postcode correct is",
                same_day_delivery: "Levering op dezelfde dag",
                same_day: "Op dezelfde dag",
                choose_this_offer: "Kies deze aanbieding",
                ultra_fast: "Ultra snel",
                ultra_fast_text: "Met de Ultra Snelle optie, ophalen en leveren op dezelfde dag"








            }
        }
    }
}