import { createRouter, createWebHistory } from 'vue-router'
import LogIn from '../views/LogIn.vue'
import store from '../store'
import Information from '../views/Information.vue'
import OrdersProgress from '../views/OrdersProgress.vue'
import OrdersPending from '../views/OrdersPending.vue'
import OrdersDelivered from '../views/OrdersDelivered.vue'
import EditShipment from '../views/EditShipment.vue'
import Tracking from '../views/Tracking.vue'
import Users from '../views/Users.vue'
import Servicepoints from '../views/Servicepoints.vue'
import Statistics from '../views/Statistics.vue'
import Invoices from '../views/Invoices.vue'
import Refunds from '../views/Refunds.vue'
import Clients from '../views/Clients.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: LogIn
  },
  {
    path: '/login',
    name: 'LogIn',
    component: LogIn
  },
  {
    path: '/information',
    name: 'Information',
    component: Information,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/shipments-progress',
    name: 'OrdersProgress',
    component: OrdersProgress,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/shipments-pending',
    name: 'OrdersPending',
    component: OrdersPending,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/shipments-delivered',
    name: 'OrdersDelivered',
    component: OrdersDelivered,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/edit/shipment/:shipment_id',
    name: 'EditShipment',
    component: EditShipment,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/track',
    name: 'Tracking1',
    component: Tracking
  },
  {
    path: '/track/:shipment_id',
    name: 'Tracking2',
    component: Tracking
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/service-points',
    name: 'Servicepoints',
    component: Servicepoints,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/statistics',
    name: 'Statistics',
    component: Statistics,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/invoices',
    name: 'Invoices',
    component: Invoices,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/refunds',
    name: 'Refunds',
    component: Refunds,
    meta: {
      requireLogin: true
    }
  },
  {
    path: '/clients',
    name: 'Clients',
    component: Clients,
    meta: {
      requireLogin: true
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireLogin) && !store.state.isAuthenticated) {
    next({ name: 'LogIn', query: { to: to.path } });
  } else {
    next()
  }
})

export default router
